import React from "react";

// compoment for ActionsPreFlop
const ActionsPreFlop = ({
   btn,
   btn3betActiveButton,
   if3betpushed,
   display3BetChart,
   idx,
}) => {
   return (
      // render button if activ change color and save value from button
      <button
         disabled={!btn3betActiveButton.includes(btn)}
         className={`${if3betpushed? "btnActiv" : "btnUnActiv"}` }
         key={idx}
         onClick={display3BetChart}
      >
          vs {btn}
      </button>
   );
};

export default ActionsPreFlop;
