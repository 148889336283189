import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getUser } from "../features/auth/authSlice";
import {
   getAllCharts,
} from "../features/images/imagesSlice";
import Player1PostitionButtons from "../components/Player1PostitionButtons";
import ActionsPreFlop from "../components/ActionsPreFlop";
import Player2PostitionButtons from "../components/Player2PostitionButtons";
import BigBlindButtons from "../components/BigBlindButtons";
import Image from "../components/Image";
import FaqBtn from "../components/FaqBtn";

/**
 * The MainPageNew component is responsible for displaying buttons and images.
 * It retrieves values from the buttons and inserts user info into pushedBtn.
 *
 * to possibilty :
 * a) (I am using this option) when user is log in fetch all buttons combinations with links to charts , and filter
 * them by clicking on the buttons. When last button id clicekd == display charts
 *
 * b) when user is log in fetch all buttons combinations with out links to charts,
 * and filter them by clicking on the buttons. Last button clicekd display send
 * query to backend to get links to charts charts
 * Clicking on a button sends a query to the backend.
 */
const MainPageNew = () => {
   // unpack state.image
   const { allCharts } = useSelector((state) => state.images);
   const { user } = useSelector((state) => state.auth);

   // get value from pushed button and change classname(when clicked change color on blue) in
   // activ button, init value for default charts
   const [pushedBtn, setPushedBtn] = useState([]);

   // bigBlindActivButtons available to click by user
   const [bigBlindActivButtons, setBigBlindActivButtons] = useState([]);
   // filtered buttons list with combinations
   const [bigBlindActivListButtons, setBigBlindActivListButtons] = useState([]);

   // buttons avilable to click as a position Player1
   const [player1PositionsActivButtons, setPlayer1PositionsActivButtons] = useState([]);
   // filtered by BigBlind and RiseActions and player1position, list with buttons combinations,
   const [player1PositionsListButtons, setPlayer1PositionsListButtons] = useState([]);

   // buttons avilable to click as a position Player2
   const [player2PositionsActivButtons, setPlayer2PositionsActivButtons] = useState([]);
   // filtered by BigBlind and RiseActions and player1position and player2position list with buttons combinations,
   const [player2PositionsListButtons, setPlayer2PositionsListButtons] =  useState([]);

   // display 3bet chart
   const [if3betpushed, setIf3betpushed] = useState(false);
   const [btn3betActiveButton, setBtn3betActiveButton] = useState([]);
   const [btn3betActiveList, setBtn3betActiveList] = useState([]);

   // set faq button
   const [faqbtn, setFaqbtn] = useState(false);

   // display message
   const [player2Message, setPlayer2Message] = useState('');
   const [btn3betMessage, setBtn3betMessage] = useState('');
   const [text, setText] = useState('')

   const textMessage = "There is no such scenario";
   const defaultBtnCombination = ["100", "RFI", "UTG"];

   const dispatch = useDispatch();
   const navigate = useNavigate();

   // list with buttons names to render components
   const BigBlindButtonsList = ["300", "200", "100", "75", "50", "40", "30", "20", "15", "10"];
   const PlayerPostitionsList = ["UTG", "UTG+1", "MP", "HJ", "CO", "BTN", "SB", "BB"];
   const playersActions = ["3bet"];

   useEffect(() => {
      // check if user is in localStore, if not navigate to login,
      // each time user reload page, get user data, and base on that fetch allCharts depends of user.subsciprion
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user) {
         return navigate("/login/");
      }
      dispatch(getUser()).then(() => {
         dispatch(getAllCharts(user));
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      // setBigBlindActivButtons list of available button to click by user
      setBigBlindActivButtons(() =>
               allCharts.map((arr) => arr[0])
            );

      // set default charts for BB, RFI, Player1
      let searchCharts = charts(allCharts, defaultBtnCombination, 2);
      let activPlayer1PositionList = [];
      if (searchCharts.length > 0) {
         if (searchCharts.length > 1 ) {
            activPlayer1PositionList = searchCharts.filter(
               (arr) => arr.find((btn) => btn === defaultBtnCombination[2]) === arr[2]
            )
         } else {
            activPlayer1PositionList = searchCharts;
         }
         BBAndPlayer1ActivButtons(defaultBtnCombination);
         setPushedBtn(defaultBtnCombination.slice(0,4));
         setPlayer1PositionsListButtons(activPlayer1PositionList);
         setPlayer2PositionsListButtons([]);
         setPlayer2PositionsActivButtons(() =>
            activPlayer1PositionList.map((arr) => arr[3])
         )
      } else {
         setText(textMessage)
      }
      // line under deactivate warning message about array of dependencies
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [allCharts]);

   const charts = (charts, updatedBtn, lastIndex) => {
      // filter charts by pushedBtn
      return charts.filter(arr => {
        for (let i = 0; i <= lastIndex; i++) {
          if (arr[i] !== updatedBtn[i]) {
            return false;
          }
        }
        return true;
      });
    };

   const clearPlayer1 = () => {
      setPlayer1PositionsActivButtons([]);
      setPlayer1PositionsListButtons([]);
   };

   const clearPlayer2 = () => {
      setPlayer2PositionsActivButtons([]);
      setPlayer2PositionsListButtons([]);
   };

   const clear3bet = () => {
      setIf3betpushed(false);
      setBtn3betActiveButton([]);
      setBtn3betActiveList([]);
   }

   const player1 = (e) => {
      const updatedPushedBtn = [e.target.innerText, 'RFI']

      let  activBigBlindList = charts(allCharts, updatedPushedBtn, 1);

      setPushedBtn(updatedPushedBtn.slice(0,2))
      setBigBlindActivListButtons(activBigBlindList);
      setPlayer1PositionsListButtons([])
      setPlayer1PositionsActivButtons(() => activBigBlindList.map((arr) => arr[2]));
   }

   const player2 = (e, updatedPushedBtn) => {
      // search for activ buttons for player2
      let searchCharts = charts(allCharts, updatedPushedBtn, 2);

      let activPlayer1PositionList = [];
      if (searchCharts.length > 0) {
         if (searchCharts.length === 1 ) {
            activPlayer1PositionList = searchCharts;
         } else {
            activPlayer1PositionList = searchCharts.filter(
               (arr) => arr.find((btn) => btn === pushedBtn[2]) === arr[2]
            )
         }
         let activPlayer2PositionList = [];
         if (activPlayer1PositionList.length > 0) {
            // check if player2 position is available for clicked btn
            activPlayer2PositionList = activPlayer1PositionList.filter(
               (arr) => arr.find((btn) => btn === pushedBtn[3]) === arr[3]
            );
            if (activPlayer2PositionList.length === 0){
               setPlayer2Message(textMessage)
            }
            setPushedBtn(updatedPushedBtn);
            setPlayer1PositionsListButtons(activPlayer1PositionList);
            setPlayer2PositionsListButtons(activPlayer2PositionList);
            setPlayer2PositionsActivButtons(() =>
               activPlayer1PositionList.map((arr) => arr[3])
            );
         } else {
            setPlayer2Message(textMessage)
            player1(e)
            clearPlayer2()
         }
      } else {
         setPlayer2Message(textMessage)
      }
   }

   const list3Bet = (updatedPushedBtn, ifClicked=false) => {
      // set 3bet button active btn, display, or message
      const filteredCharts = allCharts.filter(
      (arr) =>
         arr[0] === updatedPushedBtn[0] &&
         arr[1] === playersActions[0] &&
         arr[2] === updatedPushedBtn[2] &&
         arr[3] === updatedPushedBtn[3]
      );
      if (filteredCharts.length > 0) {
         setBtn3betActiveButton('3bet');
         setBtn3betActiveList(filteredCharts);
      } else {
         clear3bet()
         if (ifClicked){
            setBtn3betMessage(textMessage)
         }
      }
   }

   const BBAndPlayer1ActivButtons = (updatedPushedBtn) => {
      // depend of BB set activ buttons for Player1
      let  activBigBlindList = charts(allCharts, updatedPushedBtn, 1);
      setBigBlindActivListButtons(activBigBlindList);
      setPlayer1PositionsActivButtons(() => activBigBlindList.map((arr) => arr[2]));
   }
   const setBB = (e) => {
      /*
       Funkcja do ustawiania przycisków na podstawie BB i RFI (RFI jest default warością)
       Sprawdz ile przycisków jest kliknętych"
       * Jeśli pushedBtn.length > 3
       *    sprawdza czy są dostepne chart:
       *       jeśli tak:
       *          -ustawia nowe wartość dla Player1 i Player2 i 3bet
       *       jeśli nie:
       *          - sprawdza czy są charty ale dla kliknętych 3 ostatnich przycisków
       *          - jeśli tak ustawie nowe wartość dla Player1 i altywnych Player2 jeśli są dostępne
       *          - jeśli nie ustawie nowe aktywne przyciski dla Player1
       * Jeśli pushedBtn.length === 3
       *    sprawdza czy są charty ale dla kliknętych 3 ostatnich przycisków
       *        - jeśli tak ustawie nowe wartość dla Player1 i altywnych Player2 jeśli są dostępne
       *        - jeśli nie ustawie nowe aktywne przyciski dla Player1
       *   Jeśli pushedBtn.length === 2 //POZYCJA POCZĄTKOWA:
       *    - ustawie nowe aktywne przyciski dla Player1
       */
      setText('');
      setPlayer2Message('');
      setBtn3betMessage('');
      const updatedPushedBtn = [e.target.innerText, 'RFI', pushedBtn[2], pushedBtn[3]];

      if (pushedBtn.length >= 4) {
         let searchCharts = charts(allCharts, updatedPushedBtn, 3);

         // if found charts for clicked btn
         if (searchCharts.length > 0) {
            setPushedBtn(updatedPushedBtn)
            setPlayer1PositionsListButtons(searchCharts)
            setPlayer2PositionsListButtons(searchCharts)
            BBAndPlayer1ActivButtons(updatedPushedBtn)

            // search for activ buttons for player2
            player2(e, updatedPushedBtn)

            // set 3bet
            list3Bet(updatedPushedBtn, if3betpushed)

         // if not found charts for clicked btn
         } else  {
            setText(textMessage)
            clear3bet()
            const updatedPushedBtn = [e.target.innerText, 'RFI', pushedBtn[2]]
            // search for activ buttons for player1
            BBAndPlayer1ActivButtons(updatedPushedBtn)

            // search for activ buttons for player2
            let searchCharts = charts(allCharts, updatedPushedBtn, 2);
            let activPlayer1PositionList = [];
            if (searchCharts.length > 0) {
               if (searchCharts.length === 1 ) {
                  activPlayer1PositionList = searchCharts;
               } else {
                  activPlayer1PositionList = searchCharts.filter(
                     (arr) => arr.find((btn) => btn === pushedBtn[2]) === arr[2]
                  )
               }
               setPushedBtn(updatedPushedBtn.slice(0,4));
               setPlayer1PositionsListButtons(activPlayer1PositionList);
               setPlayer2Message(textMessage)
               setPlayer2PositionsListButtons([]);
               setPlayer2PositionsActivButtons(() =>
                  activPlayer1PositionList.map((arr) => arr[3])
            )
            // search for activ buttons for player1
            } else {
               player1(e)
               clearPlayer2()
            }
         }

      // search for BB, RFI, Player1
      }  else if (pushedBtn.length === 3) {
         clear3bet()
         const updatedPushedBtn = [e.target.innerText, 'RFI', pushedBtn[2]]
         let searchCharts = charts(allCharts, updatedPushedBtn, 2);
         let activPlayer1PositionList = [];

         // if found charts
         if (searchCharts.length > 0) {
            if (searchCharts.length > 1 ) {
               activPlayer1PositionList = searchCharts.filter(
                  (arr) => arr.find((btn) => btn === updatedPushedBtn[2]) === arr[2]
               )
            } else {
               activPlayer1PositionList = searchCharts;
            }
            BBAndPlayer1ActivButtons(updatedPushedBtn);
            setPushedBtn(updatedPushedBtn.slice(0,4));
            setPlayer1PositionsListButtons(activPlayer1PositionList);
            setPlayer2PositionsListButtons([]);
            setPlayer2PositionsActivButtons(() =>
               activPlayer1PositionList.map((arr) => arr[3])
            )
         // if not found charts for clicked btn
         } else {
            setText(textMessage)
            player1(e)
            clearPlayer2()
         }

      // search activ btn for player 1
      } else {
         clear3bet()
         clearPlayer2()
         player1(e);
      };
   };

   // get value and change class in list[2]
   const postitionPlayer1 = (e) => {
      setText('');
      setBtn3betMessage('');
      const updatedPushedBtn = [
         pushedBtn[0],
         pushedBtn[1],
         e.target.innerText
      ];

      let activPlayer1PositionList = [];
      if (bigBlindActivListButtons.length > 1) {
         activPlayer1PositionList = bigBlindActivListButtons.filter(
            (arr) => arr.find((btn) => btn === e.target.innerText) === arr[2]
         );
      } else {
         activPlayer1PositionList = bigBlindActivListButtons;
      }

      if (pushedBtn.length > 3){
         let activPlayer2PositionList = [];
         if (activPlayer1PositionList.length > 0) {
            // check if player2 position is available for clicked btn
            activPlayer2PositionList = activPlayer1PositionList.filter(
               (arr) => arr.find((btn) => btn === pushedBtn[3]) === arr[3]
            );
            // if player2 position is available
            if (activPlayer2PositionList.length > 0){
               updatedPushedBtn[3] = pushedBtn[3]

               // set 3bet
               list3Bet(updatedPushedBtn, if3betpushed)

            // if player2 position is not available for clicked btn
            } else {
               clear3bet();
               setPlayer2Message(textMessage)
            }
         } else {
            clear3bet();
            setPlayer2Message(textMessage)
         }
         setPlayer2PositionsListButtons(activPlayer2PositionList);
      } else {
         setPlayer2Message('');
         clear3bet()
         setPlayer2PositionsListButtons([]);
      }

      setPlayer2PositionsActivButtons(() =>
         activPlayer1PositionList.map((arr) => arr[3])
      );
      setPlayer1PositionsListButtons(activPlayer1PositionList);
      setPushedBtn(updatedPushedBtn);
   };

   // get value and change class in list[3]
   const postitionPlayer2 = (e) => {
      setText('');
      setBtn3betMessage('');
      setPlayer2Message('');
      const updatedPushedBtn = [
         pushedBtn[0],
         pushedBtn[1],
         pushedBtn[2],
         e.target.innerText,
      ];
      let activPlayer2PositionList = [];
      if (player1PositionsListButtons.length > 1) {
         activPlayer2PositionList = player1PositionsListButtons.filter(
            (arr) => arr.find((btn) => btn === e.target.innerText) === arr[3]
         );
      } else {
         activPlayer2PositionList = player1PositionsListButtons;
      }

      // check if 3bet is available to click
      list3Bet(updatedPushedBtn, if3betpushed)
      setPushedBtn(updatedPushedBtn);
      setPlayer2PositionsListButtons(activPlayer2PositionList);
   };

   const display3BetChart = (e) => {
      setText('');
      setPlayer2Message('');
      setBtn3betMessage('');
      setIf3betpushed(true);
   };

   const handleReset = () => {
      setPushedBtn([]);
      setBigBlindActivListButtons([]);
      clearPlayer1();
      clearPlayer2();
      clear3bet();
      setBtn3betMessage('');
      setText('');
      setPlayer2Message('');
   };

   if (!user) {
      return navigate("/login/");
   } else {
      return (
         <>
            <div className="containerBoxNew">
               <div className="allButtons">

                  <div className="btnlist">
                     {/* Render PreFlopActionbuttons */}
                     <div className="clearAnd3Bet">
                        {playersActions.map((btn, idx) => (
                           <ActionsPreFlop
                              btn3betActiveButton={btn3betActiveButton}
                              btn={btn}
                              idx={idx}
                              if3betpushed={if3betpushed}
                              display3BetChart={display3BetChart}
                           />
                        ))}
                        <button className="clearBtn" onClick={handleReset}>
                           Clear
                        </button>
                     </div>

                     {/* Function for render BigBlindButtons */}
                     {BigBlindButtonsList.map((btn, idx) => (
                        <BigBlindButtons
                           bigBlindActivButtons={bigBlindActivButtons}
                           btn={btn}
                           idx={idx}
                           pushedBtn={pushedBtn}
                           setBB={setBB}
                        />
                     ))}

                     <div className="faqbtn">
                        <button className="" onClick={() => setFaqbtn(true)}>
                              FAQ
                        </button>

                        <FaqBtn
                           show={faqbtn}
                           onHide={() => setFaqbtn(false)}
                           />
                     </div>
                  </div>

                  {/* Function for render player1 Postition */}
                  <div className="btnlist mg-b-40">
                     {PlayerPostitionsList.map((btn, idx) => (
                        <Player1PostitionButtons
                           player1PositionsActivButtons={
                              player1PositionsActivButtons
                           }
                           btn={btn}
                           idx={idx}
                           pushedBtn={pushedBtn}
                           postitionPlayer1={postitionPlayer1}
                        />
                     ))}
                  </div>

                  {/* Function for render player2 postition */}
                  <div className="btnlist mg-b-40">
                     {PlayerPostitionsList.map((btn, idx) => (
                        <Player2PostitionButtons
                           player2PositionsActivButtons={
                              player2PositionsActivButtons
                           }
                           btn={btn}
                           idx={idx}
                           pushedBtn={pushedBtn}
                           postitionPlayer2={postitionPlayer2}
                        />
                     ))}
                  </div>

               </div>
               {/* Display charts */}
                  <div className="charts">
                     {
                        btn3betActiveList.length > 0 && if3betpushed ? (
                           <div>
                              <h5 className="text"> Action: vs 3BET </h5>
                              <Image image={btn3betActiveList[0][5]} />
                           </div>
                        ) : (
                           btn3betMessage ? (
                           <div>
                              <h5 className="text"> Action: vs 3BET </h5>
                              <h2 className="text message">{btn3betMessage}</h2>
                           </div>
                           ) : (
                              <span className="text"></span>
                           )
                        )
                     }
                     {
                        player2PositionsListButtons.length > 0 ? (
                           <div>
                              <h5 className="text"> Player 2: {pushedBtn[3]} </h5>
                              <Image image={player2PositionsListButtons[0][5]} />
                        </div>
                     ) : (
                        player2Message ? (
                           <div>
                              <h5 className="text"> Player 2 </h5>
                              <h2 className="text message">{player2Message}</h2>
                           </div>
                           ) : (
                              <span className="text"></span>
                           )
                     )}
                     <br/>
                     {
                        player1PositionsListButtons.length > 0   ? (
                           <div>
                              <h5 className="text"> Player 1: {pushedBtn[2]} </h5>
                              <Image image={player1PositionsListButtons[0][4]} />
                           </div>
                        ) : (
                           text ? (
                              <div>
                                 <h2 className="">{text}</h2>
                              </div>
                              ) : (
                                 <span className="text"></span>
                              )
                        )
                     }
                  </div>
            </div>
         </>
      );
   }
};

export default MainPageNew;
