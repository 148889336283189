import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import yellow from '../images/yellow.png';
import brown from '../images/brown.png';
import blue from '../images/blue.png';
import red from '../images/red.png';

function FaqBtn(props) {
  return (
    <Modal
      {...props}
      size="m"
      aria-labelledby="contained-modal-title-vcenter"
      animation
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        FAQ
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Colors:</h4>
            <p className='ml-5 mb-2'>
              <img className='colors-pictrue' src={brown} alt='brown'/> - RAISE
            </p>
            <p className='ml-5 mb-2'>
              <img className='colors-pictrue' src={yellow} alt='yellow'/>  - CALL
            </p>
            <p className='ml-5 mb-2'>
              <img className='colors-pictrue' src={blue} alt='blue'/>  - 2BB
            </p>
            <p className='ml-5 mb-40'>
              <img className='colors-pictrue' src={red} alt='red'/>  - ALL IN
            </p>
        <h4 class >Sizings:</h4>
            <p className='ml-5 mb-2'>10bb [All-in/Fold vs 50bb Stacks]</p>
            <p className='ml-5 mb-2'>15bb [2x Open (SB 2.5x), BTN/SB LIMP]</p>
            <p className='ml-5 mb-2'>20bb [2x Open (SB 2.5x), BTN/SB LIMP]</p>
            <p className='ml-5 mb-2'>30bb [2x Open (SB 2.8x), SB LIMP]</p>
            <p className='ml-5 mb-2'>40bb [2.2x Open (SB 2.8x), SB LIMP]</p>
            <p className='ml-5 mb-2'>50bb [2.2x Open (SB 2.8x), SB LIMP]</p>
            <p className='ml-5 mb-2'>75bb [2.5x Open (SB 2.8x), SB LIMP]</p>
            <p className='ml-5 mb-2'>100bb [2.25x Open (SB 3x), SB LIMP]</p>
            <p className='ml-5 mb-2'>200bb [2.5x Open (SB 3x), SB LIMP]</p>
            <p className='ml-5 mb-2'>300bb [2.5x Open (SB 3x), SB LIMP]</p>
            <p className='ml-5 mb-2'>3bet From 2.5x to 3x IP,  3.5 to 5x OOP </p>
            <p className='ml-5 mb-2'>4bet 2.2x to 2.5x </p>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default FaqBtn;

