import React from 'react'


// compoment display charts
const Image = ({image}) => {
  return (
    <img src={image} alt="Logo1" className="singleChart betchart"/>
  )
}

export default Image

//  <div className="RangeViewerComponentRangesRange">
//  frament for google drive api 
//  <img src={`data:image/jpeg;base64,${image.file}`} alt="Logo1" className="rangeManual"/> 
// </div> 